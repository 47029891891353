import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DOMAIN, UI } from '@summa/models';
import { CursorPanelService } from '@summa/shared/ui/dialogs';

import { LightDimmerComponent } from './light-dimmer';

@Component({
  selector: 'summa-light-card',
  templateUrl: './light-card.component.html',
  styleUrls: ['./light-card.component.scss'],
})
export class LightCardComponent implements OnDestroy, OnChanges {
  get percentPath(): number {
    // 466 is the maximum number stroke-dashoffset needs to disappear the second path
    const percentage = this.percentage === -1 ? 100 : this.percentage;
    return 466 - percentage * 3.4;
  }

  get glowEffect(): string {
    return `0px 0px 28px ${this.percentage / 2}px rgb(170 122 86 / 25%)`;
  }

  get showIcon(): string {
    return this.turnedOn ? 'lightbulb-outline' : 'lightbulb-off-outline';
  }
  @Input() card: UI.Card;
  @Input() showExtraInfoButton = false;
  @Input() loading;

  @Output() extraInfoClick = new EventEmitter<string>();
  @Output() cardStateChange = new EventEmitter<{ state: boolean; dimValue?: number }>();

  destroy$ = new Subject();

  percentage = 0;
  syncing = false;

  get turnedOn(): boolean {
    return this.card.state === 'on';
  }

  get unknown(): boolean {
    return this.card.state === 'unknown';
  }

  get selectedScene(): DOMAIN.Scene {
    return this.card.scenes.find((s) => s.id === this.card.selectedSceneId);
  }

  constructor(private cursorPanelService: CursorPanelService) { }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnChanges({ card }: SimpleChanges): void {
    if (card?.currentValue) {
      this.syncing = false;
      this.percentage = card.currentValue.dimValue;
    }
  }

  toggleCardState(): void {
    this.cardStateChange.next({ state: !this.turnedOn });
  }

  openExtraInfo(event: MouseEvent, cardId: string): void {
    event.stopPropagation();
    this.extraInfoClick.next(cardId);
  }

  openDimDialog(event: MouseEvent | TouchEvent): void {
    this.cursorPanelService.open(LightDimmerComponent, event, { dimValue: this.percentage });
    let first = true;
    const cursorPanelData = this.cursorPanelService
      .getData()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: { dimValue: number }) => {
        if (!first) {
          this.syncing = true;
          this.cardStateChange.next({ state: true, dimValue: data.dimValue });
        }
        first = false;
      });

    this.cursorPanelService.close$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      cursorPanelData.unsubscribe();
    });
  }
}
