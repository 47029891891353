import { ENV } from '@summa/models';

export const environment: ENV.Environment = {
  app: {
    name: 'summa-portal-dashboard',
    version: '3.0.0',
    isPortal: true,
  },
  productId: 'spd',
  production: true,
  api: {
    httpHost: `https://${window.location.hostname}/graphql`,
    webSocketHost: `wss://${window.location.hostname}/graphql`,
  },
  token: 'portal-token',
  floorplanUrl: 'https://summa-floorplan-storage.s3-eu-west-1.amazonaws.com',
  fixtureLogThreshold: 60, // in seconds
  sliderDebounceTime: 600,

  awsConfig: {
    region: 'eu-west-1',
    baseUrl: 'https://s3.amazonaws.com/',
    floorplan: {
      bucket: 'summa-floorplan-storage',
      folder: 'floorplans',
      aclType: 'private',
    },
    firmware: {
      bucket: 'summa-driver-firmware',
      folder: 'production',
      aclType: 'private',
    },
    iam: {
      id: 'AKIAUV3YCI2CBDSQ2ZV7',
      key: 'AyzGnJHgXMEXtacppZqn0ZXyBuVK9pazXLE27I1c',
    },
  },
};
