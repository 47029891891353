<mat-toolbar>
  <mat-toolbar-row>
    <a [routerLink]="['/']" class="pl-2 pt-1"> <img src="assets/images/logo.svg" height="50" alt="Summa logo" /></a>
    <span class="pageTitle">{{ title | i18nextCap }}</span>
    <span class="spacer"></span>
    <ng-content></ng-content>
    <mat-icon *ngIf="menuItems" class="mr-2" aria-hidden="false" aria-label="Apps">{{ 'common:icons.apps' | i18next }}</mat-icon>
    <mat-icon
      *ngIf="!hideUser"
      class="mr-2 my-profile-button"
      [matMenuTriggerFor]="user"
      aria-hidden="false"
      aria-label="Personal settings"
      svgIcon="account"
    >
    </mat-icon>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="showPrevious">
    <div class="previous ml-2" (click)="previous.next()">
      <mat-icon>{{ 'common:icons.back' | i18next }}</mat-icon>
      <small>{{ 'dictionary:back' | i18nextCap }}</small>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="!hideBreadcrumb">
    <xng-breadcrumb class="summa-breadcrumbs" [autoGenerate]="false"></xng-breadcrumb>
  </mat-toolbar-row>
</mat-toolbar>

<mat-menu #user="matMenu" class="user-dropdown-menu">
  <section class="user-box">
    <section class="user-intro">
      <div class="user-intro-prefix">{{ 'common:menu.signed-in-as' | i18nextCap }}</div>
      <div class="user-intro-input">{{ currentUser }}</div>
    </section>
    <mat-divider></mat-divider>
    <button *ngIf="!hideProfile" mat-menu-item [routerLink]="['/my-profile']">
      <mat-icon aria-label="edit-profile" svgIcon="account-edit"></mat-icon>
      <span>{{ 'common:dictionary.my-profile' | i18nextCap }}</span>
    </button>
    <button mat-menu-item (click)="logout.next()">
      <mat-icon aria-label="Logout" svgIcon="power"></mat-icon>
      <span>{{ 'common:dictionary.logout' | i18nextCap }}</span>
    </button>
  </section>
</mat-menu>
