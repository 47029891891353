<div class="p-4" fxLayout="row" fxLayoutGap="16px" fxFlex="100%" fxLayoutAlign="center start">
  <div fxLayout="column" fxLayoutGap="16px" class="w-100">
    <div fxLayout="row wrap" fxLayoutGap="16px">
      <div class="reseller-card xl p-3">
        <div class="header">
          {{'reseller:common.admin-panels' | i18nextCap}}
          <div class="header-tools">
            <div class="filter">
              <mat-form-field floatLabel="never">
                <mat-icon matPrefix [svgIcon]="'common:icons.search' | i18next"></mat-icon>
                <input matInput type="search" class="search-input" (keyup)="filterData($event)" #input />
              </mat-form-field>
            </div>
            <div class="create" title="{{'reseller:tooltips.create-reseller' | i18nextCap}}" (click)="openCreateReseller()">
              <mat-icon fxFlexAlign="start center" [svgIcon]="'common:icons.add' | i18next"></mat-icon>
            </div>
          </div>
        </div>

        <mat-table [dataSource]="resellers" class="w-100" matSort>
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'reseller:overview.table-headers.id' | i18nextCap}}</mat-header-cell>
            <mat-cell *matCellDef="let reseller">{{ reseller.id }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'reseller:overview.table-headers.name' | i18nextCap}}</mat-header-cell>
            <mat-cell class="highlight" *matCellDef="let reseller">{{ reseller.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="contactName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'reseller:overview.table-headers.contact.name' | i18nextCap}}</mat-header-cell>
            <mat-cell *matCellDef="let reseller">
              <div>{{ reseller.contact.name }}</div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="contactEmail">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'reseller:overview.table-headers.contact.email' | i18nextCap}}</mat-header-cell>
            <mat-cell *matCellDef="let reseller">
              <div>{{ reseller.contact.email }}</div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="contactPhone">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'reseller:overview.table-headers.contact.phone' | i18nextCap}}</mat-header-cell>
            <mat-cell *matCellDef="let reseller">
              <div>{{ reseller.contact.phone }}</div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
          <mat-row *matRowDef="let myRowData; columns: columnsToDisplay" class="clickable" [routerLink]="['/reseller/', myRowData.id]"></mat-row>
          <div class="filter-notification" *matNoDataRow>{{'reseller:overview.filter-notification' | i18nextCap}} "{{input.value}}"</div>
        </mat-table>
        <mat-paginator class="pagination" [pageSizeOptions]="[15, 25, 50]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
</div>
