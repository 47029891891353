<div
  *ngIf="!loading"
  fxFlexFill
  summaLongPress
  (isPressed)="openDimDialog($event)"
  (click)="toggleCardState()"
  class="summa-card summa-card-white py-3"
  [class.clickable]="!unknown"
  fxLayout="column"
  fxLayoutAlign="start center"
>
  <mat-icon *ngIf="showExtraInfoButton" (click)="openExtraInfo($event, card.id)" class="info-button" svgIcon="dots-vertical"></mat-icon>
  <!-- <mat-icon *ngIf="unknown" [title]="'component:light-card.unknown-info' | i18nextCap" class="unknown-info" svgIcon="exclamation-thick"></mat-icon> -->

  <div class="title-container" fxLayout="row" fxLayoutAlign="center" [title]="card.title">
    <h2 class="m-0 p-0 mb-1 title">{{ card.title }}</h2>
  </div>

  <!-- LIGHT COMPONENT -->
  <span class="sub-header">{{ selectedScene?.name || 'component:light-card.no-scene' | i18next | uppercase }}</span>
  <div fxLayout="column" fxLayoutAlign="start center">
    <div class="circle mt-2">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewbox="0 0 100 100">
        <path class="background" d="M 62 120 A 70 70 90 1 1 166 120" style="fill: none" />
        <path
          [ngStyle]="{ 'stroke-dashoffset': percentPath }"
          class="brightness"
          [class.light-on]="turnedOn"
          d="M 62 120 A 70 70 90 1 1 166 120"
          style="fill: none"
        />
      </svg>
    </div>

    <div *ngIf="syncing" class="light">
      <mat-spinner mode="indeterminate" diameter="25"></mat-spinner>
    </div>
    <mat-icon
      *ngIf="!syncing"
      class="light"
      [class.light-on]="turnedOn"
      [ngStyle]="turnedOn && { 'box-shadow': glowEffect }"
      [svgIcon]="showIcon"
    ></mat-icon>
    <div class="sub-label" fxLayout="column" fxLayoutAlign="start center" [class.light-on]="turnedOn" [ngSwitch]="card.state">
      <!-- ON -->
      <ng-container *ngSwitchCase="'on'">
        <div *ngIf="percentage !== -1; else infinite">{{ percentage }}%</div>
        <ng-template #infinite>
          <mat-icon [svgIcon]="'infinity'"></mat-icon>
        </ng-template>
        <div class="small">{{ 'component:light-card.brightness' | i18nextCap }}</div>
      </ng-container>
      <!-- OFF -->
      <ng-container *ngSwitchCase="'off'">
        <div>{{ 'component:light-card.off' | i18nextCap }}</div>
        <div class="small">&nbsp;</div>
      </ng-container>
      <!-- UNKNOWN -->
      <ng-container *ngSwitchCase="'unknown'">
        <div>{{ 'component:light-card.unknown' | i18nextCap }}</div>
        <div class="small">&nbsp;</div>
      </ng-container>
    </div>
  </div>
  <!-- END: LIGHT COMPONENT -->
</div>
