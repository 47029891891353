<div class="p-3 login-form-container gradient-dark-normal" fxFlex="0 1 600px" fxFlex.xs="100%">
  <h1 fxLayout="column" class="summa-logo w-100" >
    <img src="assets/images/logo.svg" alt="SLP logo" class="logo-img" />
  </h1>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group px-3">
      <mat-form-field class="form-field mt-1 w-100">
        <mat-label>{{ 'dashboard:login.username' | i18next }}</mat-label>
        <input type="text" formControlName="username" matInput />
        <mat-error *ngIf="form.get('username')?.hasError('required')">
          {{ 'validations.is-required' | i18next: { field: 'dashboard:login.username' } }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="form-field mt-1 w-100">
        <mat-label>{{ 'dashboard:login.password' | i18next }}</mat-label>
        <input type="password" formControlName="password" matInput />
        <mat-error *ngIf="form.get('password')?.hasError('required')">
          {{ 'validations.is-required' | i18next: { field: 'dashboard:login.password' } }}
        </mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="error" class="alert alert-danger-border mt-2 mx-2">
      {{ error | i18nextCap }}
    </div>
    <div fxLayout="row" fxLayoutAlign="center" class="form-group mt-3 px-3">
      <button fxFlex class="text-uppercase button button--primary" [disabled]="loading" mat-flat-button color="primary">
        {{ 'buttons.login' | i18next }}
      </button>
    </div>
  </form>
</div>
